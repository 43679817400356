// src/components/WaitlistForm.js
import React, { useState } from 'react';
import { db, collection, addDoc, query, where, getDocs } from '../firebase/firebase';

const WaitlistForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setIsAlreadyRegistered(true);
        setErrorMessage("This email is already registered");
      } else {
        await addDoc(collection(db, "users"), {
          firstName: firstName,
          email: email,
          timestamp: new Date(),
        });
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsAlreadyRegistered(false);
    setErrorMessage('');
  };

  return (
    <div className="form-container">
      {isSubmitted ? (
        <p className="thank-you-message">Thank You!</p>
      ) : (
        <form className="waitlist-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className={isAlreadyRegistered ? "input-error" : ""}
              />
            </div>
            <div className="input-wrapper">
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={handleEmailChange}
                required
                className={isAlreadyRegistered ? "input-error" : ""}
              />
              {isAlreadyRegistered && (
                <p className="error-message">{errorMessage}</p>
              )}
            </div>
          </div>
          <button type="submit">Join Waitlist</button>
        </form>
      )}
      <p className="waitlist-message">Join the 500+ others who are waiting</p>
    </div>
  );
};

export default WaitlistForm;
