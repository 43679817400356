// Import the functions you need from the SDKs you need
import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.6.1/firebase-app.js';
import { getFirestore, collection, addDoc, query, where, getDocs } from 'https://www.gstatic.com/firebasejs/9.6.1/firebase-firestore.js';
import { getAnalytics,  logEvent} from "https://www.gstatic.com/firebasejs/9.6.1/firebase-analytics.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAce5nkFa3JZLkJmLs3okXdvI_yPtluuLY",
  authDomain: "finalyst-prelaunch-01.firebaseapp.com",
  projectId: "finalyst-prelaunch-01",
  storageBucket: "finalyst-prelaunch-01.appspot.com",
  messagingSenderId: "288827841849",
  appId: "1:288827841849:web:2146562d6771577d2b9ce8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const analytics = getAnalytics(app);

export { db, analytics, logEvent, collection, addDoc, query, where, getDocs };