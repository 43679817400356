// src/App.js
import React from 'react';
import './styles/style.css';
import frame1 from './img/frame1.png';
import frame2 from './img/frame2.png';
import frame3 from './img/frame3.png';
import frame4 from './img/frame4.png';
import logo from './img/logo2.png';
import WaitlistForm from './components/WaitlistForm';

const DecorativeLines = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      pointerEvents: 'none',
      overflow: 'hidden'
    }}>
      <svg width="100%" height="100%" viewBox="0 0 1000 1000" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path className="decorative-path" d="M200 -100 Q250 250 200 500 T250 1100" 
              stroke="url(#gradient)" strokeWidth="2" fill="none" />
        <path className="decorative-path" d="M800 1100 Q750 750 800 500 T750 -100" 
              stroke="url(#gradient)" strokeWidth="2" fill="none" />
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#78E4FF" />
            <stop offset="100%" stopColor="#54B4FE" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};


function App() {
  return (
    <div className="container">
      <DecorativeLines />
      <div className="top-section">
        <div className="left-content">
          <img src={logo} alt="Finalyst Logo" className="logo" />
          <h1>Analyze stocks <br /> in minutes</h1>
          <p className="subheading">AI powered data terminal</p>
          <WaitlistForm />
        </div>
        <div className="right-content">
          <img src={frame1} alt="Frame 1" />
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-left">
          <img src={frame2} alt="Frame 2" />
        </div>
        <div className="bottom-right">
          <p className="bottom-text">Get financial data as reported by publicly traded companies</p>
        </div>
      </div>
      <div className="third-section">
        <div className="third-left">
          <p className="third-text">Up to date economical data</p>
        </div>
        <div className="third-right">
          <img src={frame3} alt="Frame 3" />
        </div>
      </div>
      <div className="fourth-section">
        <div className="fourth-left">
          <img src={frame4} alt="Frame 4" />
        </div>
        <div className="fourth-right">
          <p className="fourth-text">Automate Equity Research Reports with AI</p>
        </div>
      </div>
      <div className="fifth-section">
        <p className="fifth-text">Make the decision</p>
        <button className="join-button" onClick={topFunction}>Join Finalyst</button>
      </div>
    </div>
  );
}

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export default App;
